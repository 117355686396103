import { Box, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import BlogRoll from "../../components/BlogRoll";
import HeroComponent from "../../components/HeroComponent";
import Layout from "../../components/Layout";
import useHover from "../../hooks/useHover";

const BlogIndexPage = ({ location }) => {
  const [heroTextRef, isHeroTextHovered] = useHover();
  const scrollHere = useRef(null);

  useEffect(() => {
    if (isHeroTextHovered) {
      console.log("hovered");
    }
  }, [isHeroTextHovered]);

  return (
    <Layout location={location}>
      <Box
        as="section"
        position="relative"
        flex="1"
        height="100%"
        background="#333333"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        minH="85vh"
        maxH="672px"
      >
        <Box
          position="relative"
          zIndex="5"
          px={{ base: "24px", md: "6rem" }}
          textAlign="center"
        >
          <Box
            display="flex"
            color="#FFFFFF"
            fontSize={{ base: "64px", md: "96px", lg: "120px" }}
          >
            <motion.div
              animate={{ opacity: 1, transition: { duration: 1.2 } }}
              initial={{ opacity: 0 }}
            >
              <Text
                as="h1"
                color="#FFFFFF"
                fontSize={{ base: "64px", md: "96px", lg: "120px" }}
                fontFamily="'Antonio'"
                mr={{ base: 4, md: 8 }}
              >
                Our
              </Text>
            </motion.div>

            <motion.span
              ref={heroTextRef}
              onClick={() => {
                scrollHere.current.scrollIntoView({ behavior: "smooth" });
                window.scrollTo({
                  top: 600,
                  behavior: "smooth",
                });
              }}
              animate={{
                opacity: 1,
                transition: { duration: 1.2, delay: 0.6 },
              }}
              initial={{ opacity: 0 }}
              whileHover={{
                y: -12,
                transition: {
                  ease: "easeInOut",
                  duration: 0.6,
                  repeat: Infinity,
                  repeatType: "mirror",
                },
              }}
              style={{
                color: "#C3F9E0",
                width: "100%",
                position: "relative",
                fontFamily: "Antonio",
                cursor: "pointer",
              }}
            >
              Insights
            </motion.span>
          </Box>
        </Box>
        <Box
          position="relative"
          zIndex="5"
          display="flex"
          justifyContent="center"
          fontSize={{ base: "20px", md: "24px", lg: "32px" }}
        >
          <motion.span
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 1.6, delay: 1.2, ease: "easeInOut" },
            }}
            initial={{ opacity: 0, y: -80 }}
            style={{
              position: "relative",
              fontFamily: "Montserrat",
              lineHeight: "40px",
              color: "#ffffff",
              fontWeight: 600,
            }}
          >
            Check out our latest stories!
          </motion.span>
        </Box>
        <Box>
          <Box
            position="absolute"
            zIndex="4"
            width="100%"
            height="100%"
            top="0"
            left="0"
            right="0"
            bottom="0"
            opacity="0.5"
            backgroundColor={isHeroTextHovered ? "secondary.700" : "#000000"}
            backgroundBlendMode={isHeroTextHovered ? "multiply" : "initial"}
            transition="2s all ease-in-out"
          />
          <HeroComponent>
            <Box
              height="100%"
              backgroundImage="url('/img/ae-news-hero.jpg')"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
            />
          </HeroComponent>
        </Box>
      </Box>

      <Box
        className="wrapper-xl"
        my={{ base: "4rem", md: "8rem" }}
        ref={scrollHere}
      >
        <BlogRoll />
      </Box>
    </Layout>
  );
};

export default BlogIndexPage;
